.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Use viewport height to ensure full height */
  // max-width: 100vw;
  overflow-x: hidden;
}

.content-container {
  /* This should be the class of your main content container */
  flex-grow: 1; /* This will make the content container grow and take up available space */
}
