// .p-tag {
//   height: fit-content;
// }

// .p-editor-container {
//   width: 100%;
//   margin-top: 20px;
// }

// .right-align {
//   text-align: right;
// }

// .p-radiobutton-box {
//   background-color: #cfb09566;
//   border-color: black;
//   border-width: 1px;
// }
// .p-highlight .p-radiobutton-icon {
//   background-color: black;
//   height: 8px;
//   width: 8px;
// }

// .p-autocomplete-dropdown {
//   background-color: #cfb09566;
//   border: none;

//   .p-icon {
//     color: black;
//   }
// }

// .p-autocomplete-multiple-container {
//   border: none;
// }

// .p-inputtext {
//   border: 1px solid black;
//   border-radius: 3px;
// }

// .p-autocomplete {
//   border: 1px solid black;
//   border-radius: 3px;
//   .p-inputtext {
//     border: none;
//     border-radius: unset;
//   }
// }

.p-radiobutton.p-highlight .p-radiobutton-box {
}

.p-button {
  font-size: 20px;
  font-weight: 500;
}

.mobile .p-button {
  font-size: 12px;
  font-weight: 500;
}

.p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-token {
  font-size: 20px;
  font-weight: 500;
}

.mobile
  .p-autocomplete
  .p-autocomplete-multiple-container
  .p-autocomplete-token {
  font-size: 12px;
  font-weight: 500;
}

.p-focus {
}

.p-autocomplete .p-autocomplete-multiple-container:not(.p-disabled):hover {
}

.p-button:focus {
}

.p-autocomplete-multiple-container.p-component.p-inputtext {
  width: 100%;
}

.p-autocomplete-input-token input {
  width: 0;
}

.p-inputtext {
  font-size: 20px;
}

.mobile .p-inputtext {
  font-size: 12px;
}
