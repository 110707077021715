.grid-container {
  display: grid;
  grid-template-columns: 125px auto; /* Adjust the 50px to fit the size of the small blank square */
  grid-template-rows: 125px auto; /* Adjust the 50px to fit the size of the small blank square */
  gap: 10px; /* Space between the grid items */
}

.row-item {
  grid-column: 2 / 3; /* This positions the row item to the right of the small square */
  grid-row: 1 / 2; /* This positions the row item in the first row */
  height: 100%;
}

.row-labels {
  display: flex;
  flex-direction: row; /* This is default and can be omitted */
  gap: 5px; /* Adjust the gap between labels as needed */
  height: 100%;
  align-items: flex-end;

  p {
    width: 20px;
    margin-bottom: 12px;
    text-align: center;
    transform: rotate(-90deg);
    /* Use transform-origin to control the rotation point */
    transform-origin: center;
    /* Adjust the width and height as necessary after rotation */
    white-space: nowrap; /* Prevent text from wrapping */
  }

  .row-label-space {
    margin-right: 15px;
  }
}

.column-labels {
  margin-right: 3px;
  gap: 5px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  p {
    height: 20px;
    margin: 0px;
    display: flex;
    align-items: center;
  }

  .column-label-space {
    margin-bottom: 15px;
  }
}

.column-item {
  grid-column: 1 / 2; /* This positions the column item below the small square */
  grid-row: 2 / 3; /* This positions the column item in the second row */
}

.big-square {
  grid-column: 2 / 3; /* This positions the big square to the right, filling the rest */
  grid-row: 2 / 3; /* This positions the big square at the bottom, filling the rest */
  background-color: #d3d3d36b;
}

.p-checkbox-box {
  height: 20px;
  width: 20px;
}

.easter-logic-puzzle-inputs {
  .p-dropdown-trigger {
    display: none;
  }
}

.leaflet-bottom.leaflet-right {
  display: none;
}

.leaflet-container-hotcold {
//   transform: rotate(-90deg);
}

.font-code {
  font-family: Wingdings;
}

@font-face {
  font-family: "Wingdings";
  font-style: normal;
  font-weight: normal;
  src: local("Wingdings"), url("../../../public/fonts/wingding.woff") format("woff");
}
