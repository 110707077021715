/* Base styles for mobile-first approach */
.section-1 {
  width: 100%;
  display: flex;
  flex-direction: column; /* default to column for mobile */
  .rsvp-padding {
    padding: 30px 30px;
  }

  .content-1 {
    .p-autocomplete-multiple-container {
      width: 100%;
      background-color: #cfb09566;
    }
  }
}

.content-1,
.content-2 {
  width: 100%; /* default width for mobile */
}
/* Media query for desktop screens */
@media screen and (min-width: 769px) {
  .section-1 {
    flex-direction: row; /* change to row for desktop */
    gap: 20px;
    .content-1 {
      width: 35%; /* width for content-1 on desktop */
      .rsvp-padding {
        padding: 30px 100px;
      }
    }

    .content-2 {
      width: 65%; /* remaining width for content-2 on desktop */
    }
  }
}

.section-2 {
  width: 100%;
  display: flex;
  flex-direction: column; /* default to column for mobile */

  .content-1 {
    width: 100%; /* default width for mobile */
    h1 {
      font-size: 40px;
    }

    p {
      font-size: 20px;
    }
  }
}

@media screen and (min-width: 769px) {
  .section-2 {
    flex-direction: row; /* change to row for desktop */
    gap: 20px;
    .content-1 {
      width: 45%; /* width for content-1 on desktop */
      h1 {
        font-size: 40px;
      }

      p {
        font-size: 35px;
      }
    }

    .content-2 {
      width: 55%; /* remaining width for content-2 on desktop */
    }
  }
}

.section-4 {
  width: 100%;
  display: flex;
  flex-direction: column; /* default to column for mobile */

  .content-1 {
    width: 100%; /* default width for mobile */
    h1 {
      font-size: 40px;
    }

    p {
      font-size: 20px;
    }
  }
}

@media screen and (min-width: 769px) {
  .section-4 {
    flex-direction: row; /* change to row for desktop */
    gap: 20px;
    .content-1 {
      width: 40%; /* width for content-1 on desktop */
      h1 {
        font-size: 40px;
      }

      p {
        font-size: 35px;
      }
    }

    .content-2 {
      width: 60%; /* remaining width for content-2 on desktop */
    }
  }
}

.home-content {
  width: 100%;
  padding: 10px;
}

@media screen and (min-width: 769px) {
  .home-content {
    padding: 50px;
  }
}
