body,
html {
  height: 100vh;
  margin: 0;
  font-family: "Noto Sans SC" !important;
}
#root {
  display: flex;
  flex-direction: column;
  height: 100vh;
  margin: 0;
  padding: 0;
}

h3 {
  font-size: 24px;
  font-weight: 400;
}

.mobile h3 {
  font-size: 12px !important;
  font-weight: 400;
}

p {
  font-size: 20px;
  font-weight: 400;
}

.mobile p {
  font-size: 12px !important;
  font-weight: 400;
}

a {
  font-size: 20px;
  font-weight: 400;
  color: #00000057;
  margin-block-start: 1em;
  margin-block-end: 1em;
}

.mobile a {
  font-size: 12px !important;
  font-weight: 400;
  color: #00000057;
  margin-block-start: 1em;
  margin-block-end: 1em;
}

// Set variable --font-family
:root {
  --font-family: "Noto Sans SC";
}
// ::-webkit-scrollbar {
//   z-index: 1001;
//   width: 10px;
//   color: red;
// }
// ::-webkit-scrollbar-thumb {
//   background-color: red;
//   z-index: 1001;
// }
@import "./Footer.scss";
@import "./App.scss";
@import "./PrimeReact.scss";
@import "./HomeSections.scss";
@import "./HomeSection/Main.scss";
@import "./Easter/puzzle.scss";
