.flex-column-mobile {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.countdown-subtitle-2-mobile {
  font-size: 30px;
}

@media screen and (min-width: 769px) {
  .flex-column-mobile {
    flex-direction: row;
  }
  .countdown-subtitle-2-mobile {
    font-size: 40px;
  }
}
